import { ExtractGetterProps } from 'shared-definitions/util-types'
import PageContainer from 'shared-components/service/PageContainer'
import { getTagPageProps } from 'props-getters/tag'
import TagTemplate from 'shared-components/templates/TagTemplate'
import { GetStaticPaths } from 'next'
import Layout from 'components/Layout'
import { getds } from 'ds'
import { useCallback } from 'react'
import PlaywireTagged from 'shared-components/ad/PlaywireTagged'
import styleUtils from 'shared-components/styles/util.module.css'

const TagPage: React.VFC<ExtractGetterProps<typeof getTagPageProps>> = ({
  preview,
  page,
  common,
  ts,
}) => {
  const pageLoader = useCallback(
    async (pageNumber: number, perPage: number) => {
      const dataSource = await getds({ ts })
      const result = await dataSource.getTagPage(page.slug, pageNumber, perPage)

      if (result && result.data?.resource === 'tag-page') {
        return result.data.posts.items
      }
      return []
    },
    [ts, page.slug]
  )
  return (
    <PageContainer layoutProps={{}} layout={Layout} props={{ page, preview, common, ts }}>
      <TagTemplate
        sideBar={<PlaywireTagged className={styleUtils.sidebarEl} type="med_rect_atf" />}
        page={page}
        pageLoader={pageLoader}
      />
    </PageContainer>
  )
}

// eslint-disable-next-line @typescript-eslint/require-await
export const getStaticPaths: GetStaticPaths = async () => ({
  paths: [],
  fallback: 'blocking',
})

export const getStaticProps = getTagPageProps()

export default TagPage
